import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import Link from "../../utilities/gatsbyLink"

const SectionVideoCollage = ({
  className,
  videoUrl,
  videoUrl2,
  videoUrl3,
  videoUrl4,
  videoUrl5,
  videoUrl6,
  videoUrl7,
}) => (
  <StyledSection className={className}>
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="small-12 large-8 cell">
          {videoUrl && videoUrl.length > 0 && (
            <PlayerWrapper className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={videoUrl}
                width="100%"
                height="100%"
                controls={false}
                playIcon={<PlayerWrapper />}
              />
            </PlayerWrapper>
          )}
        </div>
        <SecondaryVideos>
          <div className="grid-x grid-padding-x">
            <div className="small-12 medium-6 secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl2}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
            <div className="small-12 medium-6  secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl3}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
            <div className="small-12 medium-6  secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl4}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
            <div className="small-12 medium-6  secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl5}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
            <div className="small-12 medium-6  secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl6}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
            <div className="small-12 medium-6 secondary-video-cell">
              {videoUrl && videoUrl.length > 0 && (
                <PlayerWrapper className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={videoUrl7}
                    width="100%"
                    height="100%"
                    controls={false}
                    playIcon={<PlayerWrapper />}
                  />
                </PlayerWrapper>
              )}
            </div>
          </div>

          <div className="small-12 cell text-uppercase">
            {/*<Link to="/tickets/" className="button large">*/}
            {/*  Join Us Live*/}
            {/*</Link>*/}
          </div>
        </SecondaryVideos>
      </div>
    </div>
  </StyledSection>
)

export default SectionVideoCollage

const StyledSection = styled.section`
  padding: 50px 0;
  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 640px) {
  }
`
const PlayerWrapper = styled.div`
  margin-bottom: 16px;
  padding-right: 0;

  :nth-child(even) {
    padding-left: 1rem;
  }
`

const SecondaryVideos = styled.div.attrs({
  className: "small-12  large-4 cell",
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;

  .secondary-video-cell:nth-child(even) {
    padding-left: 8px;
  }

  .secondary-video-cell:nth-child(odd) {
    padding-right: 8px;
  }

  @media screen and (max-width: 1024px) {
    .secondary-video-cell:nth-child(odd),
    .secondary-video-cell:nth-child(even) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  @media screen and (max-width: 640px) {
  }
`
