import React from "react"
import styled from "styled-components"

const SectionTripleList = () => (
  <StyledSection>
    <div className="grid-container">
      <div className="grid-x grid-padding-x align-center">
        <div className="small-12 large-10 cell">
          <div className="grid-container ">
            <div className="grid-x grid-padding-x align-center">
              <StatBlock>
                <Heading>Practices Sizes</Heading>
                <UnorderedList>
                  <ListItem>
                    <ListPercentage>41%</ListPercentage>
                    <ListValue>Single Clinic</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>29%</ListPercentage>
                    <ListValue>2-10 Clinics</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>22%</ListPercentage>
                    <ListValue>11-20 Clinics</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>8%</ListPercentage>
                    <ListValue>21+ Clinics</ListValue>
                  </ListItem>
                </UnorderedList>
              </StatBlock>
              <StatBlock>
                <Heading>Business Roles</Heading>
                <UnorderedList>
                  <ListItem>
                    <ListPercentage>33%</ListPercentage>
                    <ListValue>Executive</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>25%</ListPercentage>
                    <ListValue>Owner</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>20%</ListPercentage>
                    <ListValue>Director</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>9%</ListPercentage>
                    <ListValue>Office Manager or Biller</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>13%</ListPercentage>
                    <ListValue>Other</ListValue>
                  </ListItem>
                </UnorderedList>
              </StatBlock>
              <StatBlock>
                <Heading>Therapy Roles</Heading>
                <UnorderedList>
                  <ListItem>
                    <ListPercentage>80%</ListPercentage>
                    <ListValue>DPT/PT</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>8%</ListPercentage>
                    <ListValue>OT</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>5%</ListPercentage>
                    <ListValue>SLP</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>4%</ListPercentage>
                    <ListValue>ATC</ListValue>
                  </ListItem>
                  <ListItem>
                    <ListPercentage>3%</ListPercentage>
                    <ListValue>PTA</ListValue>
                  </ListItem>
                </UnorderedList>
              </StatBlock>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledSection>
)

export default SectionTripleList

const StyledSection = styled.section`
  padding: 50px 0;
  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 640px) {
  }
`
const StatBlock = styled.div`
  max-width: 296px;
  @media screen and (min-width: 1120px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
  }
`

const Heading = styled.p`
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
`
const UnorderedList = styled.ul`
  list-style: none;
  margin-left: 0;
`
const ListItem = styled.li`
  border-bottom: 1px solid #e5e9f2;
  height: 50px;
  display: flex;
  align-items: center;

  &:last-of-type {
    border: none;
  }
`
const ListValue = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
`
const ListPercentage = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  width: 88px;
`
