import React from "react"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"
import Img from "gatsby-image"
import ReactPlayer from "react-player"
import { SectionBodyCopy } from "./simpleContentCta"
// import { Link } from "gatsby"
import Link from "../../utilities/gatsbyLink"

const SectionBigList = ({
  image,
  videoUrl,
  showVideo,
  sectionHeading,
  sectionHeadingMobile,
  sectionBody,
  sectionBodyHeading,
  ctaText,
  ctaLink,
  listContent,
  className,
}) => (
  <StyledSection className={className}>
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <StyledVerticalSectionTitle>
          <VerticalSectionTitle
            svg={sectionHeading}
            mobileSvg={sectionHeadingMobile}
          />
        </StyledVerticalSectionTitle>
        <Content>
          <BodyContainer>
            <Heading>{sectionBodyHeading}</Heading>
            <SectionBodyCopy sectionBody={sectionBody} />
            {ctaLink && ctaText && ctaLink.length > 0 && ctaText.length > 0 && (
              <Link to={ctaLink} className="button large">
                {ctaText}
              </Link>
            )}
          </BodyContainer>
          {videoUrl && videoUrl.length > 0 && showVideo ? (
            <PlayerWrapper className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={videoUrl}
                width="100%"
                height="100%"
                controls={false}
                playIcon={<PlayerWrapper />}
              />
            </PlayerWrapper>
          ) : (
            <ImageContainer>
              {image && <Img fluid={image} alt="Ascend 2023" />}
            </ImageContainer>
          )}
        </Content>
        <List>
          <SectionBodyCopy sectionBody={listContent} />
        </List>
      </div>
    </div>
  </StyledSection>
)

export default SectionBigList

const StyledSection = styled.section`
  padding: 90px 0;
  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 640px) {
  }
`

const StyledVerticalSectionTitle = styled.div.attrs({
  className: "large-1 cell",
})``
const Heading = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  margin-bottom: 30px;
`
const Content = styled.div.attrs({
  className: "small-12 large-4 cell",
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const BodyContainer = styled.div.attrs({
  className: "small-12 cell",
})`
  margin-bottom: 30px;

  .button {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`
const ImageContainer = styled.div.attrs({
  className: "small-8 medium-7 text-center cell",
})`
  @media screen and (max-width: 1024px) {
    margin: 0 auto 30px;
  }
  @media screen and (max-width: 640px) {
  }
`
const PlayerWrapper = styled.div``
const List = styled.div.attrs({
  className: "small-12 large-6 cell large-offset-1",
})`
  .list-segment-title {
    font-weight: 700;
    line-height: 180%;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .list-heading {
    font-weight: 700;
    line-height: 180%;
    margin-bottom: 0;
  }

  .list-body {
    font-weight: 400;
    line-height: 180%;
  }
`
