import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Link from "../../utilities/gatsbyLink"
import Img from "gatsby-image"
import styled from "styled-components"

const SectionImageMosaic = ({ headingTag: HeadingTag = "p" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image1: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-01.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-02.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-03.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 192) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image4: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-04.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image5: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-05.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 192) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image6: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-06.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 192) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image7: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-07.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 192) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image8: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-08.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image9: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-09.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const image1 = data.image1.childImageSharp.fluid
        const image2 = data.image2.childImageSharp.fluid
        const image3 = data.image3.childImageSharp.fluid
        const image4 = data.image4.childImageSharp.fluid
        const image5 = data.image5.childImageSharp.fluid
        const image6 = data.image6.childImageSharp.fluid
        const image7 = data.image7.childImageSharp.fluid
        const image8 = data.image8.childImageSharp.fluid
        const image9 = data.image9.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="small-12 medium-6 cell">
                  <Img fluid={image1} />
                </div>
                <div className="small-12 medium-6 cell">
                  <Img fluid={image2} />
                </div>
                <div className="small-4 medium-2 cell">
                  <Img fluid={image3} />
                </div>
                <div className="small-8 medium-4 cell">
                  <Img fluid={image4} />
                </div>
                <div className="small-4 medium-2 cell">
                  <Img fluid={image5} />
                </div>
                <div className="small-4 medium-2 cell">
                  <Img fluid={image6} />
                </div>
                <div className="small-4 medium-2 cell">
                  <Img fluid={image7} />
                </div>
                <div className="small-12 medium-6 cell">
                  <Img fluid={image8} />
                </div>
                <div className="small-12 medium-6 cell">
                  <Img fluid={image9} />
                </div>
              </div>
            </div>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="small-12 cell text-center text-uppercase">
                  {/*<Link to="/tickets/" className="button large">*/}
                  {/*  Join the fun*/}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionImageMosaic

const StyledSection = styled.section`
  padding: 50px 0;

  .cell {
    padding: 0.5rem;
  }

  .button {
    margin-top: 60px;

    @media screen and (max-width: 640px) {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding: 0.5em;
    }
  }
`
