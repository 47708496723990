import React from "react"
import styled from "styled-components"
import IconLearn from "../../images/svg/inline-svg/icon-learn.svg"
import IconNetwork from "../../images/svg/inline-svg/icon-network.svg"
import IconGrow from "../../images/svg/inline-svg/icon-grow.svg"

const SectionTopThree = () => (
  <StyledSection>
    <div className="grid-container">
      <div className="grid-x grid-padding-x top-three-cards">
        <HighlightCard className="small-12 medium-4 cell">
          <IconLearn />
          <CardTitle>Learn</CardTitle>
          <p className="section-body">
            In-depth sessions and workshops will provide you with tons of
            practical, game-changing takeaways you can immediately apply to
            improve your business strategy. Plus, therapists can earn CEUs for
            attending.
          </p>
        </HighlightCard>
        <HighlightCard className="small-12 medium-4 cell">
          <IconNetwork />
          <CardTitle>network</CardTitle>
          <p className="section-body">
            You’ll have the opportunity to meet, mingle, and share ideas with
            seasoned professionals from around the country. You never know what
            inspiration, partnerships, or friendships you’ll gain during this
            three-day event.
          </p>
        </HighlightCard>
        <HighlightCard className="small-12 medium-4 cell">
          <IconGrow />
          <CardTitle>grow</CardTitle>
          <p className="section-body">
            Right now, therapy professionals have a unique opportunity to step
            into a leading role in their patients’ healthcare teams. Join the
            discussion, and challenge yourself to take your practice—and our
            profession—to the next level.
          </p>
        </HighlightCard>
      </div>
    </div>
  </StyledSection>
)

export default SectionTopThree

const StyledSection = styled.section`
  padding: 90px 0;
  background-color: #f9fafc;
  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 640px) {
  }
`

const HighlightCard = styled.div`
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }
`
const CardTitle = styled.h2`
  font-weight: 900;
  font-size: 64px;
  line-height: 120%;
  text-transform: uppercase;
  color: #000000;
  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 130%;
    margin-top: 20px;
  }
`
